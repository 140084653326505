import React from "react";
import "./Footer.css";
const Footer = (props) => {
  return (
    <footer className={`footert ${props.class}`}>
      <div className="container">
        {props.lang === "en" ? (
          <p>All right reserved © 2024</p>
        ) : (
          <p>جميع الحقوق محفوظة © 2024</p>
        )}
        <div className="links_footer">
          {
            props.lang === "en" ?
            <>
          <a href="##">Term of use </a>
          <span> - </span>
          <a href="##">policy</a>
            </>:
            <>
            <a href="##">شروط الإستخدام</a>
            <span> - </span>
            <a href="##">سياسة الخصوصية</a>
            </>
          }
        </div>
      </div>
    </footer>
  );
};

export default Footer;
