import React, { useContext, useEffect } from "react";
import "./CouponFour.css";
import LogoFour from "../../components/couponFourC/LogoFour/LogoFour";
import ContentFour from "../../components/couponFourC/ContentFour/ContentFour";
import CouponLocation from "../../components/couponFourC/CouponLocation/CouponLocation";
import ShareFour from "../../components/couponFourC/shareFour/ShareFour";
import Footer from "../../components/coupontComponents/footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import { DataContext } from "../../contextApi/DataContext";
import { Helmet } from "react-helmet-async";
const CouponFour = () => {
  const { id } = useParams();
  const { data, setId, host } = useContext(DataContext);
  const { loading } = useContext(DataContext);
  const navigate = useNavigate();
  useEffect(() => {
    setId(id);
    if (!loading && data?.data?.coupons?.fake_page === 1) {
      navigate(`/${id}`, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    data === "notFound" && navigate("/*");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  const language = data?.data?.language;

  return (
    <>
      <Helmet>
        <title>
          {host ?? ""} || {`${data?.data?.name_en}`}
        </title>
        <meta
          name="description"
          content={`Discover exclusive discounts at ${data?.data?.name_en}, your go-to destination for unbeatable savings! Browse a diverse range of categories, from fashion to electronics, and access verified coupons for top brands. Enjoy a seamless shopping experience with regularly updated deals. Subscribe to our newsletter for the latest promotions. Shop smart, save more at yalla coupon today!`}
        />
      </Helmet>
      {
        // loading &&
        <main className="CouponFour_main">
          <div className="CouponFour_container container">
            <LogoFour data={data?.data} />
            <ContentFour data={data} />
            <CouponLocation data={data} />
            <ShareFour data={data} />
            <Footer class={"footer_four"} lang={language}/>
          </div>
        </main>
      }
    </>
  );
};

export default CouponFour;
